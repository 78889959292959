import VueRouter from 'vue-router';
import Vue from 'vue';

Vue.use(VueRouter);

let routes = [
  {
    path: '/',
    component: (resolve) => require(['../components/appLayout'], resolve),
    redirect: window.innerWidth > 768 ? '/index' : '/transitionpage',
    children: [
      // 中间页
      {
        path: '/transitionpage',
        component: (resolve) => require(['../pages/TransitionPage'], resolve),
      },
      {
        path: '/index',
        component: (resolve) => require(['../pages/index/Index'], resolve),
      },
      // 登录
      {
        path: '/login',
        component: (resolve) => require(['../pages/uc/Login'], resolve),
      },
      // 注册
      {
        path: '/register',
        component: (resolve) => require(['../pages/uc/Register'], resolve),
      },
      // 找回账号私钥
      {
        path: '/retrieveprivate',
        component: (resolve) => require(['../pages/uc/RetrievePrivateKey'], resolve),
      },
      // 重置密码
      {
        path: '/findPwd',
        component: (resolve) => require(['../pages/uc/FindPwd'], resolve),
      },
      // APP 下载
      {
        path: '/app',
        component: (resolve) => require(['../pages/uc/AppDownload'], resolve),
      },

      // ----- 模拟交易页面 -----
      {
        path: '/mock',
        component: (resolve) => require(['../pages/mockTrading'], resolve),
        redirect: '/mock/swap',
        children: [
          // U本位
          {
            path: 'swap',
            component: (resolve) => require(['../pages/swap'], resolve),
          },
          {
            path: '/mock/swap/:pair',
            component: (resolve) => require(['../pages/swap'], resolve),
            name: 'mock_SwapPair',
          },
          // 币本位
          {
            path: 'coinswap',
            component: (resolve) => require(['../pages/coinswap'], resolve),
          },
          {
            path: '/mock/coinswap/:pair',
            component: (resolve) => require(['../pages/coinswap'], resolve),
            name: 'mock_CoinSwapPair',
          },
          // 秒合约
          {
            path: 'second',
            component: (resolve) => require(['../pages/second'], resolve),
          },
          {
            path: '/mock/second/:pair',
            component: (resolve) => require(['../pages/second'], resolve),
            name: 'mock_SecondPair',
          },
          // 期权合约
          {
            path: 'option',
            component: (resolve) => require(['../pages/option'], resolve),
          },
          //我的资产
          {
            path: 'management',
            component: (resolve) => require(['../pages/management'], resolve),
          },
          //划转
          {
            path: 'transfer',
            component: (resolve) => require(['../pages/uc2/transfer'], resolve),
          },
          // 关于我们、联系我们
          {
            path: 'aboutUS',
            component: (resolve) => require(['../pages/rule/aboutUS'], resolve),
          },
          // aboutUZX
          {
            path: 'about',
            component: (resolve) => require(['../pages/rule/aboutUZX'], resolve),
          },
          // 社群
          {
            path: 'community',
            component: (resolve) => require(['../pages/community/community'], resolve),
          },
          // 交易费率
          {
            path: 'TransactionRate',
            component: (resolve) => require(['../pages/TransactionRate/TransactionRate'], resolve),
          },
        ],
      },

      // ----- 交易页面 -----
      // 币币
      {
        path: '/exchange',
        component: (resolve) => require(['../pages/exchange'], resolve),
      },
      {
        path: '/exchange/:pair',
        component: (resolve) => require(['../pages/exchange'], resolve),
        name: 'ExchangePair',
      },
      // U本位
      {
        path: '/swap',
        component: (resolve) => require(['../pages/swap'], resolve),
      },
      {
        path: '/swap/:pair',
        component: (resolve) => require(['../pages/swap'], resolve),
        name: 'SwapPair',
      },
      // 币本位
      {
        path: '/coinswap',
        component: (resolve) => require(['../pages/coinswap'], resolve),
      },
      {
        path: '/coinswap/:pair',
        component: (resolve) => require(['../pages/coinswap'], resolve),
        name: 'CoinSwapPair',
      },
      // 秒合约
      {
        path: '/second',
        component: (resolve) => require(['../pages/second'], resolve),
      },
      {
        path: '/second/:pair',
        component: (resolve) => require(['../pages/second'], resolve),
        name: 'SecondPair',
      },
      // 期权合约
      {
        path: '/option',
        component: (resolve) => require(['../pages/option'], resolve),
      },

      // --- h5的路由，pc手机适配嵌套时路由处理 ---
      {
        path: '/m',
        component: (resolve) => require(['../pages/h5Page'], resolve),
        children: [
          {
            path: '/m/trade',
            redirect: '/m/trade/cointrade',
            component: (resolve) => require(['../pages/h5Page'], resolve),
            children: [
              {
                path: '/m/trade/cointrade',
                component: (resolve) => require(['../pages/h5Page'], resolve),
              },
              {
                path: '/m/trade/flashexchange',
                component: (resolve) => require(['../pages/h5Page'], resolve),
              },
            ],
          },
          {
            path: '/m/contract',
            redirect: '/m/contract/usdtcontract',
            component: (resolve) => require(['../pages/h5Page'], resolve),
            children: [
              {
                path: '/m/contract/usdtcontract',
                component: (resolve) => require(['../pages/h5Page'], resolve),
              },
              {
                path: '/m/contract/coincontract',
                component: (resolve) => require(['../pages/h5Page'], resolve),
              },
              {
                path: '/m/contract/optioncontract',
                component: (resolve) => require(['../pages/h5Page'], resolve),
              },
              {
                path: '/m/contract/secondcontract',
                component: (resolve) => require(['../pages/h5Page'], resolve),
              },
            ],
          },
          {
            path: 'klineinfodetail',
            component: (resolve) => require(['../pages/h5Page'], resolve),
          },
          {
            path: 'accessdetail',
            component: (resolve) => require(['../pages/h5Page'], resolve),
          },
          {
            path: 'tradelog',
            component: (resolve) => require(['../pages/h5Page'], resolve),
          },
          {
            path: 'allentrust',
            component: (resolve) => require(['../pages/h5Page'], resolve),
          },
          {
            path: 'billing',
            component: (resolve) => require(['../pages/h5Page'], resolve),
          },
          {
            path: 'helpdoc',
            component: (resolve) => require(['../pages/h5Page'], resolve),
          },
          {
            path: 'contracthistoryentrustall',
            component: (resolve) => require(['../pages/h5Page'], resolve),
          },
          {
            path: 'secondentrustall',
            component: (resolve) => require(['../pages/h5Page'], resolve),
          },
        ],
      },
      {
        path: '/klineinfodetail',
        redirect: '/m/klineinfodetail',
      },
      {
        path: '/accessdetail',
        redirect: '/m/accessdetail',
      },
      {
        path: '/tradelog',
        redirect: '/m/tradelog',
      },
      {
        path: '/allentrust',
        redirect: '/m/allentrust',
      },
      {
        path: '/billing',
        redirect: '/m/billing',
      },
      {
        path: '/helpdoc',
        redirect: '/m/helpdoc',
      },
      {
        path: '/contracthistoryentrustall',
        redirect: '/m/contracthistoryentrustall',
      },
      {
        path: '/secondentrustall',
        redirect: '/m/secondentrustall',
      },
      // h5的路由，pc手机适配嵌套时路由处理  -----end-----

      {
        path: '/notice',
        component: (resolve) => require(['../pages/cms/Notice'], resolve),
      },
      //详情页
      {
        path: '/finanIndex',
        component: (resolve) => require(['../pages/finance/finanIndex'], resolve),
      },
      //金融Launchpad
      {
        path: '/financeLaunchpad',
        component: (resolve) => require(['../pages/finance/financeLaunchpad'], resolve),
      },
      // 邀请好友页面
      {
        path: '/invite',
        component: (resolve) => require(['../pages/invite/Invite'], resolve),
      },
      // 规则说明文章
      {
        path: '/ruleInfo',
        component: (resolve) => require(['../pages/invite/ruleInfo'], resolve),
      },
      // UZX返佣规则说明
      {
        path: '/ruleInfo2',
        component: (resolve) => require(['../pages/invite/ruleInfo2'], resolve),
      },
      // 奖励中心 Rewards
      {
        path: '/rewards',
        component: (resolve) => require(['../pages/rewards'], resolve),
        children: [
          // 奖励列表
          {
            path: '',
            component: (resolve) => require(['../pages/rewards/rewards'], resolve),
          },
          // 问题列表
          {
            path: 'problem',
            component: (resolve) => require(['../pages/rewards/problem'], resolve),
          },
        ],
      },
      // UZX LAB
      {
        path: '/uzxLab',
        component: (resolve) => require(['../pages/uzxLab'], resolve),
      },
      // UZX用户首次入金奖励说明
      {
        path: '/rewardsArticle',
        component: (resolve) => require(['../pages/rewards/article'], resolve),
      },
      // 闪兑
      {
        path: '/cexchange',
        component: (resolve) => require(['../pages/cexchange/cexchange'], resolve),
      },
      // 公告详情
      {
        path: '/announcement/:id',
        component: (resolve) => require(['../pages/cms/NoticeItemDetail'], resolve),
        name: 'NoticeDetail',
      },
      // 公告列表
      {
        path: '/noticeItem/:id',
        component: (resolve) => require(['../pages/cms/NoticeItem'], resolve),
      },
      // 安全中心personalCenter
      {
        path: '/personalCenter',
        component: (resolve) => require(['../pages/personalCenter/securityCenter'], resolve),
      },
      // 安全中心→邮箱绑定
      {
        path: '/emailCenter',
        component: (resolve) => require(['../pages/personalCenter/emailCenter'], resolve),
      },
      {
        path: '/authenticationCenter',
        component: (resolve) => require(['../pages/personalCenter/authenticationCenter'], resolve),
      },
      {
        path: '/phoneCenter',
        component: (resolve) => require(['../pages/personalCenter/phoneCenter'], resolve),
      },
      // 新 充币页面
      {
        path: '/recharge',
        component: (resolve) => require(['../pages/recharge'], resolve),
      },
      // 新 提币页面
      {
        path: '/withdraw',
        component: (resolve) => require(['../pages/withdraw'], resolve),
      },
      // 划转
      {
        path: '/transfer',
        component: (resolve) => require(['../pages/uc2/transfer'], resolve),
      },
      // 我的资产
      {
        path: '/management',
        component: (resolve) => require(['../pages/management'], resolve),
      },
      // 存币生息
      {
        path: '/saveCoins',
        component: (resolve) => require(['../pages/saveCoins/saveCoin'], resolve),
      },
      // 我的订单
      {
        path: '/myOrder',
        component: (resolve) => require(['../pages/myOrder/myOrder'], resolve),
      },
      // 合伙计划
      {
        path: '/partner',
        component: (resolve) => require(['../pages/partner'], resolve),
      },
      // 合伙计划规则
      {
        path: '/rule',
        component: (resolve) => require(['../pages/rule/rule'], resolve),
      },
      // 帮助中心
      {
        path: '/helpCenter',
        component: (resolve) => require(['../pages/helpCenter/helpCenter'], resolve),
      },
      // 更多问题
      {
        path: '/moreProblem',
        component: (resolve) => require(['../pages/moreProblem/moreProblem'], resolve),
      },
      // 行情>市场
      {
        path: '/price/market',
        component: (resolve) => require(['../pages/price/market'], resolve),
      },
      // 行情>机会
      {
        path: '/price/chance',
        component: (resolve) => require(['../pages/price/chance'], resolve),
      },
      // 更多问题->文章
      {
        path: '/article',
        component: (resolve) => require(['../pages/article/article'], resolve),
      },
      // 帮助中心->搜索
      {
        path: '/searchResults',
        component: (resolve) => require(['../pages/searchResults/searchResults'], resolve),
      },
      // 反馈意见
      {
        path: '/feedBack',
        component: (resolve) => require(['../pages/feedBack/feedBack'], resolve),
      },
      // 我的工单
      {
        path: '/MyWorkOrder',
        component: (resolve) => require(['../pages/myWorkOrder/myWorkOrder'], resolve),
      },
      // 我的工单详情
      {
        path: '/detail',
        component: (resolve) => require(['../pages/myWorkOrder/detail'], resolve),
      },
      // 社群
      {
        path: '/community',
        component: (resolve) => require(['../pages/community/community'], resolve),
      },
      // 新手社区
      {
        path: '/noviceCommunity',
        component: (resolve) => require(['../pages/noviceCommunity/noviceCommunity'], resolve),
      },
      // 新手社区->查看更多
      {
        path: '/moreDetials',
        component: (resolve) => require(['../pages/noviceCommunity/moreDetials'], resolve),
      },
      // UZX Dao
      {
        path: '/UZXDao',
        component: (resolve) => require(['../pages/UZXDao/UZXDao'], resolve),
      },
      // aboutUZX
      {
        path: '/about',
        component: (resolve) => require(['../pages/rule/aboutUZX'], resolve),
      },
      // 关于我们、联系我们
      {
        path: '/aboutUS',
        component: (resolve) => require(['../pages/rule/aboutUS'], resolve),
      },
      // 交易费率
      {
        path: '/TransactionRate',
        component: (resolve) => require(['../pages/TransactionRate/TransactionRate'], resolve),
      },
      // 官方验证渠道
      {
        path: '/officialChannel',
        component: (resolve) => require(['../pages/officialChannel'], resolve),
      },
    ],
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'hash',
  routes,
});

router.beforeEach((to, from, next) => {
  next();
});

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});

export default router;
